import {Component, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom"
import {
    Calendar,
    Input,
    Card,
    Image,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Collapse,
    Select,
    Tag, Button, Modal, Spin
} from "antd";
import Apiurl from "../ConstURL";
import Hosturl from "../ConstURL";

import {format} from 'date-fns'

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined, PlusCircleOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";


export default function Mainpage() {
    let params = useParams()
    const Navigate = useNavigate()
    const [workers_massive, Set_workers_massive] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalLoader, setLodalLoader] = useState(true);
    const [WorkerIdWorkShifts, setWorkerIdWorkShifts] = useState("");
    const [WorkerWorkShiftsMas, setWorkerWorkShiftsMas] = useState([]);

    const [pagenum, set_pagenum] = useState(1)

    function RenderCell(datecell, info) {
        if (info.type === "date") {
            console.log(info)
            var datecellstr = format(new Date(datecell), 'dd.MM.yyyy')
            var mas = []
            WorkerWorkShiftsMas.forEach(function (workShift) {
                if (format(new Date(workShift.Open_time), 'dd.MM.yyyy') === datecellstr) {
                    if (workShift.Closed) {
                        mas.push([format(new Date(workShift.Open_time), 'dd.MM.yyyy HH:mm:ss'), format(new Date(workShift.Close_time), 'dd.MM.yyyy HH:mm:ss'), (workShift.Alltime / (60 * 60)).toFixed(1)])
                    } else {
                        mas.push([format(new Date(workShift.Open_time), 'dd.MM.yyyy HH:mm:ss'), "*", "0.0"])
                    }
                }
            })
            return mas.map((e) => {
                return <div style={{fontSize: "15px", paddingBottom: "10px"}}>{e[0]} - {e[1]} ({e[2]} ч.)</div>
            })
        } else {
            var datecellstr = new Date(datecell)
            var allseconds = 0
            WorkerWorkShiftsMas.forEach(function (workShift) {
                if (new Date(workShift.Open_time).getMonth() === datecellstr.getMonth() && new Date(workShift.Open_time).getFullYear() === datecellstr.getFullYear()) {
                    allseconds += workShift.Alltime
                }
            })
            return <div>{(allseconds / (60 * 60)).toFixed(1)} ч.</div>
        }
    }

    function RenderMonth(datemonth) {
        var datecellstr = new Date(datemonth)
        var allseconds = 0
        WorkerWorkShiftsMas.forEach(function (workShift) {
            if (new Date(workShift.Open_time).getMonth() === datecellstr.getMonth() && new Date(workShift.Open_time).getFullYear() === datecellstr.getFullYear()) {
                allseconds += workShift.Alltime
            }
        })
        return <div>{allseconds / (60 * 60)} часов</div>
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setLodalLoader(true)
    };

    const showModal = (workerid) => {
        setWorkerIdWorkShifts(workerid)
        setLodalLoader(true)
        setIsModalOpen(true);

        fetch(Apiurl + "/worker/workshifts?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            workerid: workerid,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                setWorkerWorkShiftsMas(data.Data)
                setLodalLoader(false)

            })

    };

    useEffect(() => {
        fetch(Apiurl + "/worker/find/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: 0,
            limit: 100,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_workers_massive(data.Data)
                console.log(data.Data)
            })


    }, []);


    return (<div>
            <Modal width={"92%"} style={{top: 20}} footer={null}
                   title={<Button type={"primary"} icon={<FileExcelOutlined/>}
                                  rel="noreferrer" htmlType={"a"} target="_blank"
                                  href={Apiurl + "/worker/workshifts/excel?" + new URLSearchParams({
                                      token: localStorage.getItem("token"),
                                      workerid: WorkerIdWorkShifts
                                  })}
                   >Выгрузить смены</Button>} open={isModalOpen} onCancel={handleCancel}>
                <Flex vertical={false} justify={"center"}>
                    {isModalLoader ? <Spin size={"large"} spinning={isModalLoader}/> :
                        <Flex justify={"center"}>
                            <div><Calendar style={{width: "90vw"}}
                                           cellRender={(date1, info) => RenderCell(date1, info)}></Calendar></div>

                        </Flex>
                    }
                </Flex>

            </Modal>
            <Header/>
            <Flex justify={"center"} style={{paddingTop: 25}}>
                <Flex style={{width: "85vw", flexDirection: "row-reverse", color: "White"}}><Button type='primary'
                                                                                                    onClick={(e) => Navigate('/addworker')}
                                                                                                    icon={
                                                                                                        <PlusCircleOutlined/>}> Добавить
                    Воркера</Button></Flex>
            </Flex>
            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>

                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{padding: "20px"}}>
                    {workers_massive.length===0 && <Spin size={"large"}></Spin>}
                    {workers_massive.map((worker, index) => (
                        <Card title={<a href={"/worker/" + worker.Id}>{worker.Username}</a>} style={{width: "75vw"}}
                              extra={localStorage.getItem("admin") === "yes" &&
                                  <Button onClick={event => showModal(worker.Id)}>Смены</Button>}>
                            <Flex vertical={true} gap={"small"}>
                                <div><b>ID воркера:</b> {worker.Id}</div>

                                <div><b>Telegram:</b> <a
                                    href={"https://t.me/" + worker.Telegram_username}>@{worker.Telegram_username}</a> ({worker.Telegram_id})
                                </div>


                                {(worker.Banned) ? <Tag color={"red"}>ЗАБЛОКИРОВАН</Tag> : ""}

                            </Flex>
                        </Card>
                    ))}
                </Space>


            </Flex>


        </div>
    );

}