import {Component, useEffect, useState} from "react";

import {
    Input,
    DatePicker,
    Card,
    Image,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Collapse,
    Select,

    Popconfirm, Button, Divider, Checkbox, Modal, Spin
} from "antd";
import Apiurl from "../ConstURL";
import {format} from 'date-fns'
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined, DeleteOutlined, SendOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";
import Search from "antd/es/input/Search";
import TextArea from "antd/es/input/TextArea";

const {Text, Link} = Typography;
const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};
const disabledRangeTime = (_, type) => {
    if (type === 'start') {
        return {
            disabledSeconds: () => [55, 56],
        };
    }
    return {
        disabledSeconds: () => [55, 56],
    };
};
const {RangePicker} = DatePicker;
export default function Mainpage() {

    const [intervalId, setIntervalId] = useState(null);
    const [autoobnovlenie, setautoobnovlenie] = useState(false)

    const [tasks, settasks] = useState([])
    const [taskscount, settaskscount] = useState(0)




    const [filter_workerid, Set_filter_workerid] = useState("")
    const [filter_chatid, Set_filter_chatid] = useState("")
    const [filter_insideid, Set_filter_insideid] = useState("")
    const [filter_type, Set_filter_type] = useState("")
    const [filter_search_text, Set_filter_search_text] = useState("")
    const [filter_status, Set_filter_status] = useState("0")
    const [time_from, Set_time_from] = useState("")
    const [time_to, Set_time_to] = useState("")
    const [time_offset, Set_time_offset] = useState("")
    const [filter_filter, Set_filter_filter] = useState("")

    const [comment_text_dict, Set_comment_text_dict] = useState({})

    const [poup_answer, Set_poup_answer] = useState(false)
    const [text_input_popup, Set_text_input_popup] = useState("")
    const [id_ticket_close, Set_id_ticket_close] = useState("")

    const [chats_massive, Set_chats_massive] = useState([])
    const [workers_massive, Set_workers_massive] = useState([])
    const [users_massive, Set_users_massive] = useState({})
    const [types_massive, Set_types_massive] = useState([])

    const [types_dop_massive, Set_types_dop_massive] = useState([])

    const [pagenum, set_pagenum] = useState(1)

    const rangePresets = [
        {
            label: 'За 1 час',
            value: [dayjs().add(-1, 'h'), dayjs()],
        },
        {
            label: 'За 2 часа',
            value: [dayjs().add(-2, 'h'), dayjs()],
        },
        {
            label: 'За 12 часов',
            value: [dayjs().add(-12, 'h'), dayjs()],
        },
        {
            label: 'За 24 часа',
            value: [dayjs().add(-24, 'h'), dayjs()],
        },
    ];

    const confirmclose = (ticketid) => {
        console.log(ticketid);

        fetch(Apiurl + "/tickets/close?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                        token: localStorage.getItem("token"),
                        skip: (pagenum - 1) * 10,
                        limit: 10,
                        filter_workerid: filter_workerid,
                        filter_chatid: filter_chatid,
                        filter_insideid: filter_insideid,
                        filter_status: filter_status,
                        from_time: time_from,
                        filter_search_text: filter_search_text,

                        filter_sort: filter_filter,
                        time_offset: new Date().getTimezoneOffset().toString(),

                    }))
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            settaskscount(data.Allcount)
                            settasks(data.Data)
                            message.success("Тикет " + ticketid + " закрыт");
                        })
                } else {
                    message.error("Тикет " + ticketid + " не закрыт!");
                }
            })


    };

    const confirmdelete = (ticketid) => {
        console.log(ticketid);

        fetch(Apiurl + "/tickets/delete?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                        token: localStorage.getItem("token"),
                        skip: (pagenum - 1) * 10,
                        limit: 10,
                        filter_workerid: filter_workerid,
                        filter_chatid: filter_chatid,
                        filter_insideid: filter_insideid,
                        filter_status: filter_status,
                        from_time: time_from,
                        filter_search_text: filter_search_text,
                        filter_sort: filter_filter,
                        time_offset: new Date().getTimezoneOffset().toString(),

                    }))
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            settaskscount(data.Allcount)
                            settasks(data.Data)
                            message.success("Тикет " + ticketid + " удален");
                        })
                } else {
                    message.error("Тикет " + ticketid + " не удален!");
                }
            })


    };
    const onChangeFilterfilter_date = (text) => {
        console.log(new Date().getTimezoneOffset())
        if (text) {
            console.log(text[0].format('YYYY-MM-DD HH:mm'))
            console.log(text[1].format('YYYY-MM-DD HH:mm'))
            Set_time_from(text[0].format('YYYY-MM-DD HH:mm'))
            Set_time_to(text[1].format('YYYY-MM-DD HH:mm'))
            Set_time_offset(new Date().getTimezoneOffset().toString())
            fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                token: localStorage.getItem("token"),
                skip: (1 - 1) * 10,
                limit: 10,
                filter_workerid: filter_workerid,
                filter_chatid: filter_chatid,
                filter_insideid: filter_insideid,
                filter_type: filter_type,
                filter_status: filter_status,
                filter_search_text: filter_search_text,
                from_time: text[0].format('YYYY-MM-DD HH:mm'),
                to_time: text[1].format('YYYY-MM-DD HH:mm'),
                filter_sort: filter_filter,
                time_offset: new Date().getTimezoneOffset().toString(),

            }))
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    console.log(data.Allcount)
                    settaskscount(data.Allcount)
                    settasks(data.Data)
                })

        } else {
            Set_time_from("")
            Set_time_to("")
            fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                token: localStorage.getItem("token"),
                skip: (1 - 1) * 10,
                limit: 10,
                filter_workerid: filter_workerid,
                filter_chatid: filter_chatid,
                filter_insideid: filter_insideid,
                filter_type: filter_type,
                filter_status: filter_status,
                filter_search_text: filter_search_text,
                from_time: "",
                to_time: "",
                filter_sort: filter_filter,
                time_offset: new Date().getTimezoneOffset().toString(),

            }))
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    console.log(data.Allcount)
                    settaskscount(data.Allcount)
                    settasks(data.Data)
                })
        }
    };
    const onChangeFilterWorkerId = (text) => {
        if (text) {
            text = text
        } else {
            text = ""
        }

        Set_filter_workerid(text)
        set_pagenum(1)

        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (1 - 1) * 10,
            limit: 10,
            filter_workerid: text,
            filter_chatid: filter_chatid,
            filter_insideid: filter_insideid,
            filter_type: filter_type,
            filter_status: filter_status,
            filter_search_text: filter_search_text,
            from_time: time_from,
            to_time: time_to,
            filter_sort: filter_filter,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

    };
    const onChangeFilterfilter_chatid = (text) => {
        if (text === undefined) {
            text = ""
        }
        Set_filter_chatid(text)
        set_pagenum(1)

        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (1 - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: text,
            filter_insideid: filter_insideid,
            filter_type: filter_type,
            filter_status: filter_status,
            filter_search_text: filter_search_text,
            from_time: time_from,
            to_time: time_to,
            filter_sort: filter_filter,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

    };
    const onChangeFilterfilter_insideid = (text) => {
        text = text.target.value
        Set_filter_insideid(text)
        set_pagenum(1)

        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (1 - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: filter_chatid,
            filter_insideid: text,
            filter_type: filter_type,
            filter_status: filter_status,
            filter_search_text: filter_search_text,
            from_time: time_from,
            to_time: time_to,
            filter_sort: filter_filter,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

    };

    // function UpdateFiltersTickets(){
    //     fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
    //         token: localStorage.getItem("token"),
    //         skip: (1 - 1) * 10,
    //         limit: 10,
    //         filter_workerid: filter_workerid,
    //         filter_chatid: filter_chatid,
    //         filter_insideid: filter_insideid,
    //         filter_type: filter_type,
    //         filter_status: filter_status,
    //         filter_search_text: filter_search_text,
    //         from_time: time_from,
    //         to_time: time_to,
    //         filter_sort:filter_filter,
    //time_offset: new Date().getTimezoneOffset().toString(),
    //
    //     }))
    //         .then(response => {
    //             return response.json()
    //         })
    //         .then(data => {
    //             console.log(data.Allcount)
    //             settaskscount(data.Allcount)
    //             settasks(data.Data)
    //         })
    // }
    const onChangeFilterfilter_insearchtext = (text) => {
        text = text.target.value
        console.log(text)
        // setTimeout(() => {  console.log("World!"); }, 1000);
        if (text === "") {
            Set_filter_search_text("")
        } else {
            Set_filter_search_text(text)
        }
        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (1 - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: filter_chatid,
            filter_insideid: filter_insideid,
            filter_type: filter_type,
            filter_status: filter_status,
            filter_search_text: text,
            from_time: time_from,
            to_time: time_to,
            filter_sort: filter_filter,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

        set_pagenum(1)

        //UpdateFiltersTickets();

    };
    const onChangeFilterfilter_status = (text) => {
        if(text.length===0){
            Set_filter_status("0")
        }else {
            Set_filter_status(text.join("|"))
        }


        set_pagenum(1)

        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (1 - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: filter_chatid,
            filter_insideid: filter_insideid,
            filter_type: filter_type,
            filter_status: text.join("|"),
            filter_search_text: filter_search_text,
            from_time: time_from,
            to_time: time_to,
            filter_sort: filter_filter,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

    };

    const onChangeDopType_status = (text, ticketid) => {
        //Set_filter_status(text)
        fetch(Apiurl + "/tickets/set/doptype?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,
            typedop: text,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data)
            })

        // fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (pagenum - 1) * 10,
        //     limit: 10,
        //     filter_workerid: filter_workerid,
        //     filter_chatid: filter_chatid,
        //     filter_insideid: filter_insideid,
        //     filter_type: filter_type,
        //     filter_status: filter_status
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })

    };

    const onChangeFilterType_change = (text) => {
        if (text === undefined) {
            text = ""
        }
        Set_filter_type(text)
        set_pagenum(1)

        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (1 - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: filter_chatid,
            filter_type: text,
            filter_insideid: filter_insideid,
            filter_status: filter_status,
            filter_search_text: filter_search_text,
            from_time: time_from,
            to_time: time_to,
            filter_sort: filter_filter,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

    };

    const handleChangeComments = (e, ticketid) => {
        const {name, value} = e.target;
        // comment_text_dict[ticketid]=value
        console.log(comment_text_dict)
        // Set_comment_text_dict(comment_text_dict)
        Set_comment_text_dict((prev) => ({
            ...prev,
            [ticketid]: value,
        }));
    };

    const onChangePage = (page) => {

        set_pagenum(page)

        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (page - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: filter_chatid,
            filter_insideid: filter_insideid,
            filter_status: filter_status,
            from_time: time_from,
            filter_search_text: filter_search_text,
            to_time: time_to,
            filter_sort: filter_filter,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

    };

    const onDeleteMessage = (ticketid, commentid) => {


        fetch(Apiurl + "/tickets/del/comment?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,
            commentid: commentid

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                var dataedit = tasks
                var foundIndex = dataedit.findIndex(x => x.Id === ticketid);

                dataedit[foundIndex].comments = dataedit[foundIndex].comments.filter((item) => item.Comment_id !== commentid)

                settasks(dataedit)
                console.log(tasks[foundIndex])
                fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                    token: localStorage.getItem("token"),
                    skip: (pagenum - 1) * 10,
                    limit: 10,
                    filter_workerid: filter_workerid,
                    filter_chatid: filter_chatid,
                    filter_insideid: filter_insideid,
                    filter_status: filter_status,
                    from_time: time_from,
                    filter_search_text: filter_search_text,
                    to_time: time_to,
                    filter_sort: filter_filter,
                    time_offset: new Date().getTimezoneOffset().toString(),

                }))
                    .then(response => {
                        return response.json()
                    })
                    .then(data => {
                        console.log(data.Allcount)
                        settaskscount(data.Allcount)
                        settasks(data.Data)
                    })

            })

    };

    const onSendMessage = (ticketid, comment_text) => {


        fetch(Apiurl + "/tickets/set/comment?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,
            comment_text: comment_text

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {


                fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                    token: localStorage.getItem("token"),
                    skip: (pagenum - 1) * 10,
                    limit: 10,
                    filter_workerid: filter_workerid,
                    filter_chatid: filter_chatid,
                    filter_insideid: filter_insideid,
                    filter_status: filter_status,
                    from_time: time_from,
                    filter_search_text: filter_search_text,
                    to_time: time_to,
                    filter_sort: filter_filter,
                    time_offset: new Date().getTimezoneOffset().toString(),

                }))
                    .then(response => {
                        return response.json()
                    })
                    .then(data => {
                        console.log(data.Allcount)
                        settaskscount(data.Allcount)
                        settasks(data.Data)

                        comment_text_dict[ticketid] = ""
                        console.log(comment_text_dict)
                        Set_comment_text_dict(comment_text_dict);

                    })

            })

    };

    const onChangeFilterfilter_filter = (text) => {
        console.log(text)
        text = text.target.checked
        if (text) {
            text = "1"
        } else {
            text = ""
        }
        // setTimeout(() => {  console.log("World!"); }, 1000);
        Set_filter_filter(text)
        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (1 - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: filter_chatid,
            filter_insideid: filter_insideid,
            filter_type: filter_type,
            filter_status: filter_status,
            filter_search_text: filter_search_text,
            from_time: time_from,
            to_time: time_to,
            filter_sort: text,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

        set_pagenum(1)

        //UpdateFiltersTickets();

    };

    const onChangeAvtoObnovlenie = (text) => {
        setautoobnovlenie(text.target.checked)
    };

    const reopenticket = (ticketid) => {

        fetch(Apiurl + "/tickets/reopen?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticket_id: ticketid,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {

                    fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                        token: localStorage.getItem("token"),
                        skip: (pagenum - 1) * 10,
                        limit: 10,
                        filter_workerid: filter_workerid,
                        filter_chatid: filter_chatid,
                        filter_insideid: filter_insideid,
                        filter_status: filter_status,
                        from_time: time_from,
                        filter_search_text: filter_search_text,
                        to_time: time_to,
                        filter_sort: filter_filter,
                        time_offset: new Date().getTimezoneOffset().toString(),

                    }))
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            console.log(data.Allcount)
                            settaskscount(data.Allcount)
                            settasks(data.Data)
                        })

                } else {
                    message.error("Тикет " + ticketid + " не переоткрыт!");
                }
            })


    };

    const OnOtclickTicket = (ticketid) => {
        console.log(ticketid);

        fetch(Apiurl + "/tickets/otclick?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticket_id: ticketid,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                        token: localStorage.getItem("token"),
                        skip: (pagenum - 1) * 10,
                        limit: 10,
                        filter_workerid: filter_workerid,
                        filter_chatid: filter_chatid,
                        filter_insideid: filter_insideid,
                        filter_status: filter_status,
                        from_time: time_from,
                        filter_search_text: filter_search_text,
                        to_time: time_to,
                        filter_sort: filter_filter,
                        time_offset: new Date().getTimezoneOffset().toString(),

                    }))
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            console.log(data.Allcount)
                            settaskscount(data.Allcount)
                            settasks(data.Data)
                        })
                } else {
                    message.error("Тикет " + ticketid + " не удалось отклкинуться!");
                }
            })


    };

    const CloseticketUspeh = (ticketid, type) => {

        fetch(Apiurl + "/tickets/close?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticket_id: ticketid,
            close_type: type,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                        token: localStorage.getItem("token"),
                        skip: (pagenum - 1) * 10,
                        limit: 10,
                        filter_workerid: filter_workerid,
                        filter_chatid: filter_chatid,
                        filter_insideid: filter_insideid,
                        filter_status: filter_status,
                        from_time: time_from,
                        filter_search_text: filter_search_text,
                        to_time: time_to,
                        filter_sort: filter_filter,
                        time_offset: new Date().getTimezoneOffset().toString(),

                    }))
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            console.log(data.Allcount)
                            settaskscount(data.Allcount)
                            settasks(data.Data)
                        })
                } else {
                    message.error("Тикет " + ticketid + " не закрыт!");
                }
            })


    };

    const CloseOpenPopup = (ticket_idd) => {
        Set_id_ticket_close(ticket_idd)
        Set_text_input_popup("")
        Set_poup_answer(!poup_answer)
    }

    const handleChangeInput = (e) => {
        const {name, value} = e.target;

        Set_text_input_popup(value)
    };
    const Closeticketdope = () => {
        var ticketid = id_ticket_close
        if (text_input_popup === "") {
            message.error("Введите комментарий!");
            return
        }
        CloseOpenPopup()
        fetch(Apiurl + "/tickets/close?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticket_id: ticketid,
            close_type: 2,
            message: text_input_popup,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
                        token: localStorage.getItem("token"),
                        skip: (pagenum - 1) * 10,
                        limit: 10,
                        filter_workerid: filter_workerid,
                        filter_chatid: filter_chatid,
                        filter_insideid: filter_insideid,
                        filter_status: filter_status,
                        from_time: time_from,
                        filter_search_text: filter_search_text,
                        to_time: time_to,
                        filter_sort: filter_filter,
                        time_offset: new Date().getTimezoneOffset().toString(),

                    }))
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            console.log(data.Allcount)
                            settaskscount(data.Allcount)
                            settasks(data.Data)
                        })
                } else {
                    message.error("Тикет " + ticketid + " не закрыт!");
                }
            })


    };

    useEffect(() => {
        fetch(Apiurl + "/chats/get?" + new URLSearchParams({
            token: localStorage.getItem("token"),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_chats_massive(data.Data[0].Chats)
                console.log(data.Data[0].Chats)
            })

        fetch(Apiurl + "/worker/find/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: 0,
            limit: 100,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_workers_massive(data.Data)
                //console.log(data.Data)
            })

        fetch(Apiurl + "/users/info?" + new URLSearchParams({
            token: localStorage.getItem("token")

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_users_massive(data.Data)
                //console.log(data.Data)
            })

        fetch(Apiurl + "/tickets/get/doptype?" + new URLSearchParams({
            token: localStorage.getItem("token"),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_types_dop_massive(data.Data)
            })

        fetch(Apiurl + "/tickets/get/type?" + new URLSearchParams({
            token: localStorage.getItem("token"),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_types_massive(data.Data)
            })

        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (pagenum - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: filter_chatid,
            filter_type: filter_type,
            filter_insideid: filter_insideid,
            filter_status: filter_status,
            filter_search_text: filter_search_text,
            from_time: time_from,
            to_time: time_to,
            filter_sort: filter_filter,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })
    }, [pagenum]);

    function UpdateData(){
        fetch(Apiurl + "/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (pagenum - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: filter_chatid,
            filter_insideid: filter_insideid,
            filter_status: filter_status,
            from_time: time_from,
            filter_search_text: filter_search_text,
            to_time: time_to,
            filter_sort: filter_filter,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)


                if(data.Data!==tasks){
                    data.Data.forEach(item1 => {
                        if (item1.status === 1) {
                            // Ищем элемент с таким же id в array2
                            const foundItem = tasks.find(item2 => item2.Id === item1.Id);
                            // Если элемента нет или его статус 2 или 3
                            if ((!foundItem || foundItem.status === 2 || foundItem.status === 3) && document.visibilityState !== 'visible'){
                                var AudioMessage = new Audio(localStorage.getItem("NotifyType"));
                                AudioMessage.volume=Number(localStorage.getItem("NotifyVolume"))/100
                                AudioMessage.play()

                                console.log("Разница",item1.Id);
                            }
                        }
                    });
                }
                settaskscount(data.Allcount)
                settasks(data.Data)

            })
    }

    useEffect(() => {
        if (autoobnovlenie) {
            //UpdateData();
            clearInterval(intervalId);
            const id = setInterval(UpdateData, 2000); // Обновление каждые 5 секунд
            setIntervalId(id);


            return () => clearInterval(id);
        } else {

            clearInterval(intervalId);
        }
    }, [autoobnovlenie,pagenum,filter_workerid,filter_chatid,filter_insideid,filter_status,time_from,filter_search_text,time_to,filter_filter,tasks]);


    return (<div>
            <Modal
                title="Введите комментарий для закрытия тикета"
                footer={null} open={poup_answer} onCancel={(e) => CloseOpenPopup("")}

            >
                <Flex vertical={true} justify={"center"} gap={"large"}>

                    <TextArea
                        autoSize={{
                            minRows: 3,
                            maxRows: 10,
                        }}
                        onChange={(e) => handleChangeInput(e)}
                        size="large"
                        placeholder="Ваш комментарий"></TextArea>
                    <Button onClick={(e) => Closeticketdope()} type={"primary"}>Отправить</Button>
                </Flex>
            </Modal>
            <Header/>
            <Flex gap={"large"} justify={"center"} style={{paddingTop: "30px"}}>
                <RangePicker disabledTime={disabledRangeTime} format="DD.MM.YYYY HH:mm" showTime
                             onChange={onChangeFilterfilter_date}
                             presets={rangePresets}/>
                <Input
                    style={{width: 200}}
                    placeholder={"Внутренний Id"}
                    onChange={onChangeFilterfilter_insideid}
                >
                </Input>
                <Input
                    style={{width: 200}}
                    placeholder={"Поиск по тексту"}
                    onChange={onChangeFilterfilter_insearchtext}
                >
                </Input>
                <Select
                    style={{width: 200}}
                    placeholder={"Воркер"}
                    allowClear={true}
                    dropdownStyle={{width:300}}
                    onChange={onChangeFilterWorkerId}
                    options={
                        [
                            {
                                label: <span>Поиск по TG id</span>,
                                title: 'first',
                                options:
                                    workers_massive.map((chatobject, indexch) => {
                                        return {value: chatobject.Telegram_id, label: chatobject.Username}
                                    })
                                ,
                            },
                            {
                                label: <span>Поиск по username</span>,
                                title: 'second',
                                options:
                                    workers_massive.map((chatobject, indexch) => {
                                        return {value: chatobject.Id, label: chatobject.Username}
                                    })
                                ,
                            },
                        ]


                    }
                >


                </Select>
                <Select
                    placeholder={"Выберите тип"}
                    style={{width: 150}}
                    onChange={onChangeFilterType_change}
                    allowClear={true}
                    options={

                        types_massive.map((chatobject, indexch) => {
                            return {value: chatobject.Id, label: chatobject.Name}
                        })
                    }
                />
                <Select
                    placeholder={"Выберите чат"}
                    style={{width: 150}}
                    onChange={onChangeFilterfilter_chatid}
                    dropdownStyle={{width:300}}
                    allowClear={true}
                    options={

                        chats_massive.map((chatobject, indexch) => {
                            return {value: chatobject.Id, label: chatobject.Name}
                        })
                    }
                />
                {/*{value: '0', label: 'Все'},*/}
                <Select
                    placeholder={"Выберите тип заявки"}
                    // defaultValue={["0"]}
                    mode="multiple"
                    style={{width: 300}}
                    onChange={onChangeFilterfilter_status}
                    options={
                        [

                            {value: '3', label: 'Закрытые'},
                            {value: '2', label: 'В работе'},
                            {value: '1', label: 'Ожидают ответа'},
                        ]}
                />

            </Flex>
            <Flex gap={"large"} justify={"center"} style={{paddingTop: "15px"}}>
                <Checkbox onChange={onChangeFilterfilter_filter}>Сначала старые</Checkbox>
                <Checkbox onChange={onChangeAvtoObnovlenie}>Автообновление</Checkbox>
            </Flex>
            <Flex

                justify={"center"} style={{paddingTop: "10px"}}>

                <div style={{color: "rgba(255, 255, 255, 0.85)"}}>Всего заявок: {taskscount}</div>
            </Flex>
            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>
                <Button type={"primary"} icon={<FileExcelOutlined/>}
                        rel="noreferrer" htmlType={"a"} target="_blank"
                        href={Apiurl + "/tickets/all/excel?" + new URLSearchParams({
                            token: localStorage.getItem("token"),
                            skip: (1 - 1) * 10,
                            limit: 10,
                            filter_workerid: filter_workerid,
                            filter_chatid: filter_chatid,
                            filter_insideid: filter_insideid,
                            filter_type: filter_type,
                            filter_status: filter_status,
                            filter_search_text: filter_search_text,
                            from_time: time_from,
                            to_time: time_to,
                            filter_sort: filter_filter,
                            time_offset: new Date().getTimezoneOffset().toString(),

                        })}
                >
                    Выгрузить в Excel
                </Button>
            </Flex>

            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>
                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{padding: "20px"}}>
                    {tasks.length===0 && <Spin size={"large"}></Spin>}
                    {tasks.map((ticket, index) => (
                        <Card key={ticket.Id} id={ticket.Id} title={"ID: " + ticket.Id} style={{width: "90vw"}}
                              extra={<div>{(ticket.status === 1) ?
                                  <div style={{color: "#ff3c3c"}}>Ожидает ответа</div> : ((ticket.status === 2) ?
                                      <div style={{color: "#ff9201"}}>В работе</div> : (
                                          <div style={{color: "#47ff00"}}>Закрыт</div>))}</div>}>
                            <Flex vertical={true} gap={"small"}>
                                {/*<div><b>Внутренний ID:</b> {ticket.ticket_id_inside}</div>*/}
                                <div>
                                    <b>Чат:</b> {chats_massive.map((chatobj, index) => ((chatobj.Id === ticket.ticket_chat_id) ? chatobj.Name : ""))} ({ticket.ticket_chat_id})
                                </div>
                                {/*<div><b>Создал:</b> <a*/}
                                {/*    href={"https://t.me/" + ticket.ticket_username}>@{ticket.ticket_username}</a> ({ticket.ticket_user_id})*/}
                                {/*</div>*/}
                                {/*<div></div>*/}
                                <div><b>Создан:</b> {format(new Date(ticket.Ticket_Time_Create), 'dd.MM.yyyy HH:mm:ss')}
                                </div>
                                {/*href={"https://t.me/" + workers_massive.map((chatobj, index) => ((chatobj.Telegram_id === ticket.otclick_worker_tgid) ? chatobj.Telegram_username : ""))}>@{workers_massive.map((chatobj, index) => ((chatobj.Telegram_id === ticket.otclick_worker_tgid) ? chatobj.Telegram_username : ""))}</a> ({ticket.otclick_worker_tgid})*/}
                                {localStorage.getItem("admin") === "yes" && ((ticket.status === 2 || ticket.status === 3) ?
                                    <div><b>Откликнулся: </b>

                                        {workers_massive.map((chatobj, index) => ((chatobj.Id === ticket.otclick_worker_id) ? chatobj.Username : ""))}

                                    </div> : "")}
                                {(ticket.status === 3) ?
                                    <div><b>Закрыт:</b> {format(new Date(ticket.done_time), 'dd.MM.yyyy HH:mm:ss')}
                                    </div> : ""}

                                {/*<div></div>*/}
                                {/*<div></div>*/}


                                {localStorage.getItem("admin") === "yes" && ((ticket.status === 2 || ticket.status === 3) ?
                                    <div><b>Откликнулся
                                        в: </b> {format(new Date(ticket.Otclick_Time), 'dd.MM.yyyy HH:mm:ss')}
                                    </div> : "")}
                                <div></div>
                                {localStorage.getItem("admin") === "yes" && ((ticket.status === 3) ? <div>
                                    {/*<b>Тип: </b> {types_massive.map((type, index3) => ((type.Id === ticket.Done_ticket_type_id) ? type.Name : ""))}*/}
                                    <div style={{paddingTop: 0}}>
                                        <b>Доп. тип: </b> <Select
                                        defaultValue={(ticket.Done_ticket_type_id_dop) ? ticket.Done_ticket_type_id_dop : ""}
                                        style={{width: 150}}
                                        onChange={(e) => onChangeDopType_status(e, ticket.Id)}
                                        options={
                                            types_dop_massive.map((chatobject, indexch) => {
                                                return {value: chatobject.Id, label: chatobject.Name}
                                            })}
                                    />
                                    </div>
                                </div> : "")}


                                <Collapse
                                    style={{Width: "100%", marginTop: "15px"}}
                                    items={[{
                                        key: 1,
                                        label: "Основной текст",
                                        children:
                                            <Flex vertical={true} gap={"25px"}>
                                                <div>{ticket.message_text}</div>
                                                <Flex vertical={true} gap={"10px"}>
                                                    {ticket.message_reopen!=="" && <div>Комментарий возврата: {ticket.message_reopen}</div>}
                                                    {ticket.message_close!=="" && <div>Комментарий закрытия: {ticket.message_close}</div>}
                                                </Flex>

                                            </Flex>
                                    }
                                    ]}/>
                                {((ticket.dop_text.length) > 0) && <Collapse items={[{
                                    key: 1,
                                    label: "Дополнительный текст",
                                    children:
                                        <Flex vertical={true} gap={"small"}>
                                            {ticket.dop_text.map((dop_mes, index_dop) => (
                                                <div><a
                                                    href={"https://t.me/" + dop_mes.username}>{dop_mes.username}</a> : {dop_mes.text}
                                                </div>))}
                                        </Flex>
                                }]}></Collapse>}

                                {((ticket.images.length) > 0) && <Collapse items={[{
                                    key: 1,
                                    label: "Фото",
                                    children:
                                        <Image.PreviewGroup>
                                            {ticket.images.map((photos, index_dop) => (
                                                <Image width={100}
                                                       src={Apiurl + "/files/" + photos + "?token=" + localStorage.getItem("token")}/>
                                            ))}
                                        </Image.PreviewGroup>

                                }]}></Collapse>}

                                {((ticket.documents.length) > 0) && <Collapse items={[{
                                    key: 1,
                                    label: "Документы",
                                    children:
                                        <Flex>
                                            {ticket.documents.map((document, index_dop) => (
                                                <a target={"_blank"}
                                                   href={Apiurl + "/files/" + document + "?token=" + localStorage.getItem("token")}>
                                                    {document}
                                                </a>
                                            ))}
                                        </Flex>

                                }]}></Collapse>}

                                {(ticket.status === 1) && <div>
                                    <Divider orientation="left" plain>

                                    </Divider>
                                    <Button onClick={(e) => OnOtclickTicket(ticket.Id)}
                                            type={"primary"}>Откликнуться</Button>
                                </div>
                                }

                                {(ticket.status === 2) && <div>
                                    <Divider orientation="left" plain>

                                    </Divider>
                                    <Button onClick={(e) => CloseticketUspeh(ticket.Id, "1")} type={"primary"}
                                            style={{marginRight: "25px"}}>Успешно</Button>
                                    <Button onClick={(e) => CloseOpenPopup(ticket.Id)}
                                            style={{marginRight: "25px"}}>Другое</Button>
                                    {/*<Button danger*/}
                                    {/*        onClick={(e) => CloseticketUspeh(ticket.Ticket_id, "3")}>Отклонить</Button>*/}
                                </div>
                                }


                                <Divider orientation="left" plain>
                                    Комментарии
                                </Divider>
                                {ticket.comments !== null && (ticket.comments.length > 0) && <div>

                                    <Flex vertical={true} style={{width: "40%"}} gap={"middle"}>
                                        {ticket.comments.map((commentDat, index_dop) => (
                                            <Card style={{borderRadius: "23px"}} bodyStyle={{padding: "10px"}}>
                                                <Flex gap={"3px"}
                                                      style={{textAlign: localStorage.getItem("userid") === commentDat.Userid && "end"}}
                                                      vertical={true}>
                                                    <Text style={{fontSize: "1.2em"}}
                                                          keyboard={true}>{users_massive[commentDat.Userid]}</Text>
                                                    <div style={{fontSize: "1em"}}>{commentDat.Comment_text}</div>
                                                    <Flex align={"center"} justify={"space-between"}>
                                                        {localStorage.getItem("userid") === commentDat.Userid &&

                                                            <Button
                                                                onClick={(e) => onDeleteMessage(ticket.Id, commentDat.Comment_id)}
                                                                style={{marginLeft: "5px"}} type="primary" danger={true}
                                                                shape="circle" icon={<DeleteOutlined/>}/>

                                                        }
                                                        <Text type={"secondary"}
                                                              style={{fontSize: "0.8em"}}>{format(new Date(commentDat.TimeCreate), 'dd.MM.yyyy HH:mm:ss')}</Text>
                                                    </Flex>
                                                </Flex>

                                            </Card>
                                        ))}
                                    </Flex>
                                </div>}
                                <Search
                                    id={ticket.Id + "comment"}
                                    style={{marginTop: "15px", width: "40%"}}
                                    placeholder="Оставить комментарий"
                                    allowClear
                                    enterButton={<SendOutlined/>}
                                    value={comment_text_dict[ticket.Id]}
                                    onChange={(e) => handleChangeComments(e, ticket.Id)}
                                    size="middle"
                                    onSearch={(e) => onSendMessage(ticket.Id, e)}
                                />

                                {localStorage.getItem("admin") === "yes" &&

                                    <div>
                                        <Divider/>
                                        <Space
                                            size={"large"}
                                        >

                                            {ticket.status !== 3 && <Popconfirm
                                                title="Вы уверены что хотите закрыть заявку принудительно ?"
                                                onConfirm={() => {
                                                    confirmclose(ticket.Id)
                                                }
                                                }
                                                okText="Да"
                                                cancelText="Нет"
                                            >
                                                <Button type={"primary"}>Закрыть заявку принудительно</Button>
                                            </Popconfirm>}

                                            <Popconfirm
                                                title="Удалить заявку ?"
                                                description="Восстановить удаленную заявку неполучится!"
                                                onConfirm={() => {
                                                    confirmdelete(ticket.Id)
                                                }}
                                                okText="Да"
                                                cancelText="Нет"
                                            >
                                                <Button danger>Удалить заявку</Button>
                                            </Popconfirm>

                                            {ticket.status === 3 &&
                                                <Button onClick={(e) => reopenticket(ticket.Id)} danger>Переоткрыть
                                                    заявку</Button>}

                                        </Space>
                                    </div>
                                }
                            </Flex>
                        </Card>
                    ))}
                </Space>


            </Flex>
            <Flex justify={"center"} style={{padding: "45px"}}>
                <Pagination current={pagenum} total={taskscount} showSizeChanger={false} onChange={onChangePage}/>;
            </Flex>


        </div>
    );

}