import {Component, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom"
import {
    Input,
    Card,
    Image,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Collapse,
    Select,
    Tag, Statistic, Switch, Button, Slider
} from "antd";
import Apiurl from "../ConstURL";
import {format} from 'date-fns'

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";
import CountUp from "react-countup";

function makeHumanReadable(num, singular) {
    return num > 0
        ? num + (num === 1 ? ` ${singular} ` : ` ${singular} `)
        : '';
}

function toDaysMinutesSeconds(totalSeconds) {
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const days = Math.floor(totalSeconds / (3600 * 24));

    const secondsStr = makeHumanReadable(seconds, 'сек.');
    const minutesStr = makeHumanReadable(minutes, 'мин.');
    const hoursStr = makeHumanReadable(hours, 'ч.');
    const daysStr = makeHumanReadable(days, 'д.');

    return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
}
export default function Worker() {
    const Navigate = useNavigate()




    useEffect(() => {




    },[]);


    return (<div>
            <Header/>
            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>

                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{padding: "20px"}}>

                    <Card style={{width:"500px"}}>
                        <Flex vertical={true} gap={"25px"}>
                            <Flex vertical={true} gap={"5px"}>
                                <b>Звук уведомления</b>
                            <Select
                                style={{width:"500"}}
                                defaultValue={localStorage.getItem("NotifyType")}
                                onChange={(e) => {
                                    var AudioMessage = new Audio(e);
                                    AudioMessage.volume=Number(localStorage.getItem("NotifyVolume"))/100
                                    AudioMessage.play()
                                    localStorage.setItem("NotifyType",e)
                                }}
                                options={
                                    [
                                        {value: 'alert.mp3', label: 'Вариант 1'},
                                        {value: 'alert2.m4a', label: 'Вариант 2'},
                                    ]}

                            >
                            </Select>
                            </Flex>


                            <Flex vertical={true} gap={"5px"}>
                                <b>Громкость уведомления</b>
                                <Slider
                                    onChange={(e)=>{localStorage.setItem("NotifyVolume",e)}}
                                    defaultValue={localStorage.getItem("NotifyVolume")}
                                    // tooltip={{
                                    //     open: true,
                                    // }}
                                />
                            </Flex>

                            <Button
                            type={"primary"}
                            onClick={()=>{
                                var AudioMessage = new Audio(localStorage.getItem("NotifyType"));
                                AudioMessage.volume=Number(localStorage.getItem("NotifyVolume"))/100
                                AudioMessage.play()
                            }}
                            >
                                Проиграть уведомление</Button>

                        </Flex>


                    </Card>
                </Space>


            </Flex>


        </div>
    );

}